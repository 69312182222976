.formdiv{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
}
.inputfo{
    width:10vw;
    max-width: 100px;
    max-height: 40px;
    border-radius: 10px;
}
.searchbtn{
    max-width: 50px;
    border-radius: 10px;
}