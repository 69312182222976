@media only screen and (min-width: 600px) {
    .qty {
      flex-direction: row;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      
    }
  }
