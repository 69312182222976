.categorybtn{
display:flex;
flex-wrap: wrap;
background-color: black ;
color:white;
padding:10px;
padding-top: 20px;
margin: 5px;
font-size: 1rem;
border-radius: 10px;
width:150px;
height:70px;
text-align: center;
align-content: center;
justify-content: center;
}

.categorybtn:active{
    background-color: grey;
}

@media only screen and (max-width: 600px) {
    .categorybtn {
        width:80px;
        height:40px;
        font-size: 0.6rem;
    }
  }

