button,
input {
  font-family: inherit;
  font-size: 18px;
  border: 0;
}

button {
  cursor: pointer;
}

.wrapper {
  position: relative;
  text-align: center;
}

.search {
  position: relative;
  z-index: 1;
  display: inline-flex;
  padding: 5px;
  border-radius: 50px;
  transition: 0.4s;
}

.search:is(:hover, .open) {
  background: rgba(255, 255, 255, 0.06);
}

.search.open input {
  max-width: 180px;
}

.search input {
  background: transparent;
  border: 0;
  max-width: 0;
  padding: 0;
  text-indent: 20px;
  color: #ffffff;
  outline: none;
  transition: 0.4s;
}

.search input::placeholder {
  color: rgba(255, 255, 255, 0.375);
}

.nav-button {
  display: grid;
  place-items: center;
  width: 44px;
  height: 44px;
  background: transparent;
  color: #ffffff;
  border-radius: 50%;
  font-size: 24px;
  transition: 0.25s;
}

.nav-button:hover {
  background: rgba(252, 252, 252, 0.075);
}

.items {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 54px;
  display: grid;
  visibility: hidden;
  border-radius: 28px;
  opacity: 0;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.3s;
}

.items.open {
  visibility: visible;
  opacity: 1;
}

.items button {
  background: transparent;
  border: 0;
  text-align: left;
  color: #ffffff;
  padding: 20px;
  font-size: 16px;
  white-space: nowrap;
}

.items button:nth-child(even) {
  background: rgba(255, 255, 255, 0.035);
}