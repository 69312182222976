.foo{
    background-color: black;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    bottom: -1;
    font-size: 1rem;
    padding: 5%;
}

@media only screen and (max-width: 600px) {
    .foo {
     font-size: 0.6rem;
    }
  }